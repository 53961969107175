import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    textAlign: 'center',
    paddingBottom: theme.palette.contentOffsetTopBottom,
    minHeight: `max(${theme.palette.contentMinimalHeight}px, 110vh)`,
    '& h1': {
      fontSize: '5.5rem',
      fontFamily: theme.typography.textsFontFamily,
      color: theme.palette.pinkTextColor,
    },
    '& p': {
      ...theme.typography.normalGrayText,
    },
  },
  content: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 30px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  text: {
    ...theme.typography.normalGrayText,
    width: '100%',
    margin: 'auto',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 411,
      marginLeft: 0,
    },
  },
}));
